export default [
  {
    component: 'CNavTitle',
    name: 'USERS',
  },
  {
    component: 'CNavItem',
    name: '회원 관리',
    to: '/users',
    icon: 'cil-people',
  },

  {
    component: 'CNavTitle',
    name: 'WALLET',
  },
  {
    component: 'CNavItem',
    name: '마스터 지갑',
    to: '/wallets',
    icon: 'cil-wallet',
  },
  
  {
    component: 'CNavTitle',
    name: 'SYSTEM',
  },
  {
    component: 'CNavItem',
    name: '입출금 내역',
    to: '/transactionHistory',
    icon: 'cil-list-rich',
  },
  {
    component: 'CNavItem',
    name: '출금 승인',
    to: '/withdrawalApproval',
    icon: 'cil-task',
  },
  {
    component: 'CNavTitle',
    name: 'App',
  },
  {
    component: 'CNavItem',
    name: 'APP버전 관리',
    to: '/versions',
    icon: 'cil-info',
  },
  {
    component: 'CNavItem',
    name: '공지사항',
    to: '/notices',
    icon: 'cil-notes',
  },
]
