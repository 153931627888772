<template>
  <svg
    v-if="name == 'sygnet'"
    :class="['ic_' + name, customClass]"
    width="70px"
    height="70px"
    viewBox="0 0 70 70"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <defs>
      <rect id="path-1" x="0" y="0" width="70" height="70" rx="12"></rect>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-3">
        <stop stop-color="#FFC331" offset="0%"></stop>
        <stop stop-color="#FB9E00" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
        <stop stop-color="#A03E00" offset="0%"></stop>
        <stop stop-color="#FFC27A" stop-opacity="0" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="app" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-84.000000, -436.000000)">
        <g id="bi_App-icon" transform="translate(84.000000, 436.000000)">
          <mask id="mask-2" fill="white">
            <use xlink:href="#path-1"></use>
          </mask>
          <use id="Rectangle" fill="#D8D8D8" xlink:href="#path-1"></use>
          <polygon
            id="Fill-1"
            fill="#F5F5F5"
            mask="url(#mask-2)"
            points="0 0 0 2.05 0 4.1 0 6.15 0 8.2 0 10.25 0 12.3 0 14.35 0 16.4 0 18.45 0 20.5 0 22.55 0 24.6 0 26.65 0 28.7 0 30.75 0 32.8 0 34.85 0 36.9 0 38.95 0 41 2 41 4 41 6 41 8 41 10 41 12 41 14 41 16 41 18 41 20 41 22 41 24 41 26 41 28 41 30 41 32 41 34 41 36 41 38 41 40 41 42 41 44 41 46 41 48 41 50 41 52 41 54 41 56 41 58 41 60 41 62 41 64 41 66 41 68 41 70 41 70 0"
          ></polygon>
          <path
            d="M19.4,0 L17.8,0 L16.2,0 L14.6,0 L13,0 L13,1.8125 L13,3.625 L13,5.4375 L13,7.25 L13,9.0625 L13,10.875 L13,12.6875 L13,14.5 L13,16.3125 L13,18.125 L13,19.9375 L13,21.75 L13,23.5625 L13,25.375 L13,27.1875 L13,27.9125 C13,28.5131625 13.42976,29 13.96,29 L14.6,29 L16.2,29 L17.8,29 L19.4,29 L20.04,29 C20.57024,29 21,28.5131625 21,27.9125 L21,27.1875 L21,25.375 L21,23.5625 L21,21.75 L21,19.9375 L21,18.125 L21,16.3125 L21,14.5 L21,12.6875 L21,10.875 L21,9.0625 L21,7.25 L21,5.4375 L21,3.625 L21,1.8125 L21,0 L19.4,0 Z"
            id="Fill-2"
            fill="#1D1D1D"
            mask="url(#mask-2)"
          ></path>
          <polygon
            id="Fill-4"
            fill="#E6E6E6"
            mask="url(#mask-2)"
            points="16 28 16 31.25 16 34.5 16 37.75 16 41 18 41 18 37.75 18 34.5 18 31.25 18 28"
          ></polygon>
          <polygon
            id="Fill-6"
            fill="#E6E6E6"
            mask="url(#mask-2)"
            points="34 28 34 31.25 34 34.5 34 37.75 34 41 36 41 36 37.75 36 34.5 36 31.25 36 28"
          ></polygon>
          <path
            d="M37.4,0 L35.8,0 L34.2,0 L32.6,0 L31,0 L31,1.8125 L31,3.625 L31,5.4375 L31,7.25 L31,9.0625 L31,10.875 L31,12.6875 L31,14.5 L31,16.3125 L31,18.125 L31,19.9375 L31,21.75 L31,23.5625 L31,25.375 L31,27.1875 L31,27.9125 C31,28.5131625 31.42976,29 31.96,29 L32.6,29 L34.2,29 L35.8,29 L37.4,29 L38.04,29 C38.57024,29 39,28.5131625 39,27.9125 L39,27.1875 L39,25.375 L39,23.5625 L39,21.75 L39,19.9375 L39,18.125 L39,16.3125 L39,14.5 L39,12.6875 L39,10.875 L39,9.0625 L39,7.25 L39,5.4375 L39,3.625 L39,1.8125 L39,0 L37.4,0 Z"
            id="Fill-8"
            fill="#1D1D1D"
            mask="url(#mask-2)"
          ></path>
          <polygon
            id="Fill-10"
            fill="#E6E6E6"
            mask="url(#mask-2)"
            points="52 28 52 31.25 52 34.5 52 37.75 52 41 54 41 54 37.75 54 34.5 54 31.25 54 28"
          ></polygon>
          <path
            d="M55.4,0 L53.8,0 L52.2,0 L50.6,0 L49,0 L49,1.8125 L49,3.625 L49,5.4375 L49,7.25 L49,9.0625 L49,10.875 L49,12.6875 L49,14.5 L49,16.3125 L49,18.125 L49,19.9375 L49,21.75 L49,23.5625 L49,25.375 L49,27.1875 L49,27.9125 C49,28.5131625 49.42976,29 49.96,29 L50.6,29 L52.2,29 L53.8,29 L55.4,29 L56.04,29 C56.57024,29 57,28.5131625 57,27.9125 L57,27.1875 L57,25.375 L57,23.5625 L57,21.75 L57,19.9375 L57,18.125 L57,16.3125 L57,14.5 L57,12.6875 L57,10.875 L57,9.0625 L57,7.25 L57,5.4375 L57,3.625 L57,1.8125 L57,0 L55.4,0 Z"
            id="Fill-12"
            fill="#1D1D1D"
            mask="url(#mask-2)"
          ></path>
          <polygon
            id="Fill-14"
            fill="url(#linearGradient-3)"
            mask="url(#mask-2)"
            points="68 41 66 41 64 41 62 41 60 41 58 41 56 41 54 41 52 41 50 41 48 41 46 41 44 41 42 41 40 41 38 41 36 41 34 41 32 41 30 41 28 41 26 41 24 41 22 41 20 41 18 41 16 41 14 41 12 41 10 41 8 41 6 41 4 41 2 41 0 41 0 42.9333333 0 44.8666667 0 46.8 0 48.7333333 0 50.6666667 0 52.6 0 54.5333333 0 56.4666667 0 58.4 0 60.3333333 0 62.2666667 0 64.2 0 66.1333333 0 68.0666667 0 70 2 70 4 70 6 70 8 70 10 70 12 70 14 70 16 70 18 70 20 70 22 70 24 70 26 70 28 70 30 70 32 70 34 70 36 70 38 70 40 70 42 70 44 70 46 70 48 70 50 70 52 70 54 70 56 70 58 70 60 70 62 70 64 70 66 70 68 70 70 70 70 68.0666667 70 66.1333333 70 64.2 70 62.2666667 70 60.3333333 70 58.4 70 56.4666667 70 54.5333333 70 52.6 70 50.6666667 70 48.7333333 70 46.8 70 44.8666667 70 42.9333333 70 41"
          ></polygon>
          <rect
            id="ef_shadow_01"
            fill="url(#linearGradient-4)"
            opacity="0.361375355"
            mask="url(#mask-2)"
            transform="translate(35.000000, 38.000000) scale(1, -1) translate(-35.000000, -38.000000) "
            x="0"
            y="35"
            width="70"
            height="6"
          ></rect>
          <g
            id="Group-4"
            opacity="0.500994001"
            mask="url(#mask-2)"
            fill="#FFFFFF"
          >
            <g transform="translate(1.000000, 44.000000)">
              <polygon
                id="Fill-16"
                points="2.32471973 0 0 0 0 2 2.32471973 2 4.64943946 2 6.97436511 2 6.97436511 0 4.64943946 0"
              ></polygon>
              <polygon
                id="Fill-18"
                points="11.0426163 0 8.7178966 0 8.7178966 2 11.0426163 2 13.3673361 2 15.6922617 2 15.6922617 0 13.3673361 0"
              ></polygon>
              <polygon
                id="Fill-20"
                points="19.7605727 0 17.435853 0 17.435853 2 19.7605727 2 22.0852924 2 24.4102181 2 24.4102181 0 22.0852924 0"
              ></polygon>
              <polygon
                id="Fill-22"
                points="28.4785291 0 26.1538094 0 26.1538094 2 28.4785291 2 30.8032488 2 33.1281745 2 33.1281745 0 30.8032488 0"
              ></polygon>
              <polygon
                id="Fill-24"
                points="37.1964855 0 34.8717657 0 34.8717657 2 37.1964855 2 39.5212052 2 41.8461309 2 41.8461309 0 39.5212052 0"
              ></polygon>
              <polygon
                id="Fill-26"
                points="45.9144419 0 43.5897221 0 43.5897221 2 45.9144419 2 48.2391616 2 50.5640872 2 50.5640872 0 48.2391616 0"
              ></polygon>
              <polygon
                id="Fill-28"
                points="54.6323982 0 52.3076785 0 52.3076785 2 54.6323982 2 56.957118 2 59.2820436 2 59.2820436 0 56.957118 0"
              ></polygon>
              <polygon
                id="Fill-30"
                points="65.6752803 0 63.3505605 0 61.0256349 0 61.0256349 2 63.3505605 2 65.6752803 2 68 2 68 0"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script lang="js">
export default {
  props: {
     name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    strokeWidth:{
        type: String,
      default: "2",
    }, secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
   customStyle:{default:{}},
   customClass:{type: String,default:''}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 320px) {
}
</style>
