<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="require('@/assets/images/ic_profile.png')" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Admin
      </CDropdownHeader>
      <button type="button" class="dropdown-item" @click="logoutClick">
        <CIcon icon="cil-account-logout" /> 로그아웃
      </button>
    </CDropdownMenu>
  </CDropdown>
   <!-- 비밀번호 변경 -->
   <CModal backdrop="static" alignment="center" :visible="popupActive == 'P'">
    <CForm @submit.prevent="passwordEditApi">
      <CModalHeader dismiss @click="popupActive = null">
        <CModalTitle>어드민 비밀번호 변경</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow class="row g-3">
          <CCol :xs="12">
            <CFormLabel>새 비밀번호</CFormLabel>
            <CFormInput
              type="text"
              placeholder="새 비밀번호 입력"
              v-model="password"
              :class="passwordError == 'Y' && 'is-invalid'"
              @input="
                (e) => {
                  passwordError = e.target.value.length > 0 && e.target.value.length >= 8 ? 'N' : 'Y'
                }
              "
            />
            <CFormFeedback invalid>영문+숫자 조합 8자리 이상 입력해주세요.</CFormFeedback>
          </CCol>
          <CCol :xs="12">
            <CFormLabel>비밀번호 확인</CFormLabel>
            <CFormInput
              type="text"
              placeholder="비밀번호 확인"
              v-model="passwordConfirm"
              :class="passwordConfirmError == 'Y' && 'is-invalid'"
              @input="
                (e) => {
                  passwordConfirmError = e.target.value.length > 0 && password === e.target.value ? 'N' : 'Y'
                }
              "
            />
            <CFormFeedback invalid>일치하지 않습니다.</CFormFeedback>
          </CCol>
        
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color="dark" class="p-4 pt-2 pb-2" @click="popupActive = null">
          취소
        </CButton>
        <CButton
          type="submit"
          color="warning"
          class="p-4 pt-2 pb-2"
          :disabled="passwordConfirmError != 'N'"
          >확인</CButton
        >
      </CModalFooter>
    </CForm>
  </CModal>

</template>


<script>
import Session from '../modules/Session'
import { Rest, RestUrl } from '../modules/Rest'
export default {
  data: function () {
      return {
        password:'',
        passwordConfirm:'',
        passwordError:'',
        passwordConfirmError:'',
        popupActive:null,
      }
  },
  methods: {
    setIsLoading(bool) {
      this.$store.commit('setIsLoading', bool)
    },
    openPasswordEdit(){
        this.password = ''
        this.passwordConfirm = ''
        this.popupActive = 'P'
    },
    // 로그아웃
    logoutClick() {
      Session.clear()
      // this.$router.
      location.reload(true)
    },
    async passwordEditApi() {
        try {
            this.setIsLoading(true)
            const root = this
            let url = RestUrl.BASE_URL + `/change/password`

            const response = await Rest.post(url, {
              adminId: 'admin',
              password : this.password
            })

            if (response.status == 200) {
                root.popupActive = null
                this.$toast.success('어드민 비밀번호 변경 되었습니다');
            } else {
                if (response.data.message) {
                    this.$toast.error(response.data.message)
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            this.setIsLoading(false)
        }
    },
  },
}
</script>
