<template>
  <svg
    v-if="name == 'bi'"
    :class="['ic_' + name, customClass]"
    width="355px"
    height="50px"
    viewBox="0 0 355 50"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <g id="app" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-291.000000, -327.000000)"
        :fill="color"
      >
        <g id="Brand-Text_Splash" transform="translate(291.000000, 327.000000)">
          <polygon
            id="Fill-1"
            points="24.7316945 1 17.5005391 15.4247985 10.2693836 1 0 1 12.7027693 23.4750897 12.7027693 38 22.2142131 38 22.2142131 23.5012058 35 1"
          ></polygon>
          <path
            d="M40.9072052,22.3599309 L41.1015284,21.6739882 C41.9945415,18.5251332 44.2543668,16.7913726 47.4650655,16.7913726 C50.7620087,16.7913726 53.1058952,18.5218926 54.0665939,21.6631859 L54.279476,22.3599309 L40.9072052,22.3599309 Z M47.4661572,10 C38.5043668,10 32,16.2987901 32,24.9762351 C32,33.5413366 38.6495633,40 47.4661572,40 C54.3941048,40 59.9497817,36.2894282 62.1419214,30.2639349 L53.1026201,30.2639349 C51.9781659,31.9825724 49.8962882,32.9990638 47.4661572,32.9990638 C43.9454148,32.9990638 41.6179039,31.0416967 40.9126638,27.4877575 L40.7849345,26.8428633 L62.8591703,26.8428633 C62.9541485,26.086706 63,25.3888809 63,24.7234625 C63,20.678021 61.4683406,16.9652888 58.6877729,14.269048 C55.8482533,11.5155552 51.8635371,10 47.4661572,10 L47.4661572,10 Z"
            id="Fill-2"
          ></path>
          <polygon id="Fill-4" points="68 38 77 38 77 0 68 0"></polygon>
          <path
            d="M96.5,32.0557416 C92.5580299,32.0557416 89.8040508,29.1530733 89.8040508,25.0005401 C89.8040508,20.8469267 92.5580299,17.9453387 96.5,17.9453387 C100.3795,17.9453387 103.19489,20.9128227 103.19489,25.0005401 C103.19489,29.1541536 100.44197,32.0557416 96.5,32.0557416 M96.5,10 C87.519127,10 81,16.3076591 81,24.9994599 C81,33.6923409 87.519127,40 96.5,40 C105.480873,40 112,33.6923409 112,24.9994599 C112,16.3076591 105.480873,10 96.5,10"
            id="Fill-6"
          ></path>
          <path
            d="M138.885291,24.8208149 C138.885291,28.9750723 135.887697,32.1071292 131.911863,32.1071292 C127.936028,32.1071292 124.937369,28.9750723 124.937369,24.8208149 C124.937369,20.6665576 127.936028,17.5345006 131.911863,17.5345006 C135.887697,17.5345006 138.885291,20.6665576 138.885291,24.8208149 M116,24.8208149 C116,33.7577047 121.997319,40 130.585164,40 C133.719157,40 136.601664,38.9428899 138.921522,36.9443081 L139.46712,36.4741177 L140.547661,39.002891 L147,39.002891 L147,0 L138.268296,0 L138.268296,12.1529482 L137.454161,11.634757 C135.434808,10.3507336 133.060603,9.67217586 130.585164,9.67217586 C121.997319,9.67217586 116,15.90138 116,24.8208149"
            id="Fill-7"
          ></path>
          <path
            d="M172.41512,12 L166.286196,28.6635413 L159.709079,12 L150,12 L161.798853,37.5655913 L161.721093,37.7700907 C160.343031,41.3572637 159.097806,42.6306416 154.852379,42.6306416 L152.630847,42.6306416 L152.630847,50 L155.529531,50 C162.623962,50 166.166318,47.1949846 170.109349,38.4563233 L182,12 L172.41512,12 Z"
            id="Fill-8"
          ></path>
          <polygon
            id="Path"
            fill-rule="nonzero"
            points="199.756654 40 202.676806 40 210.068441 14.1281669 210.159696 14.1281669 217.368821 40 220.334601 40 228 9 225.262357 9 218.920152 35.2414307 218.828897 35.2414307 211.437262 9 208.973384 9 201.444867 35.2414307 201.353612 35.2414307 195.011407 9 192 9"
          ></polygon>
          <path
            d="M230,40 L232.883721,40 L236.372093,30.5290611 L249.302326,30.5290611 L252.837209,40 L256,40 L244.511628,9 L241.534884,9 L230,40 Z M242.790698,13.0193741 L242.883721,13.0193741 L248.372093,27.9418778 L237.255814,27.9418778 L242.790698,13.0193741 Z"
            id="Shape"
            fill-rule="nonzero"
          ></path>
          <polygon
            id="Path"
            fill-rule="nonzero"
            points="262 40 279 40 279 37.4590164 264.870712 37.4590164 264.870712 9 262 9"
          ></polygon>
          <polygon
            id="Path"
            fill-rule="nonzero"
            points="285 40 302 40 302 37.4590164 287.870712 37.4590164 287.870712 9 285 9"
          ></polygon>
          <polygon
            id="Path"
            fill-rule="nonzero"
            points="308 40 327 40 327 37.4590164 311.062972 37.4590164 311.062972 25.3546945 323.219144 25.3546945 323.219144 22.7675112 311.062972 22.7675112 311.062972 11.5409836 326.856423 11.5409836 326.856423 9 308 9"
          ></polygon>
          <polygon
            id="Path"
            fill-rule="nonzero"
            points="342.537832 40 345.462168 40 345.462168 11.5409836 355 11.5409836 355 9 333 9 333 11.5409836 342.537832 11.5409836"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
</template>
<script lang="js">
export default {
  props: {
     name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    strokeWidth:{
        type: String,
      default: "2",
    }, secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
   customStyle:{default:{}},
   customClass:{type: String,default:''}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 320px) {
}
</style>
